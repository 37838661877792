var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderHistory-box rounded-xl py-4 my-4" },
    [
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "space-between", "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table d-block" }, [
              _vm.detail.cancellationReferenceNumber
                ? _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                    _c(
                      "td",
                      {
                        staticClass:
                          "history-table-left-info d-sm-table-cell d-block",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                          },
                          [_vm._v(_vm._s(_vm.$t("label.refundId")))]
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [_vm._v(_vm._s(_vm.detail.cancellationReferenceNumber))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "history-table-left-info d-sm-table-cell d-block",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                      },
                      [_vm._v(_vm._s(_vm.$t("label.orderId")))]
                    ),
                  ]
                ),
                _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                  _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
                    _vm._v(_vm._s(_vm.detail.orderReferenceNumber)),
                  ]),
                ]),
              ]),
              _vm.detail.trackingNumber != null
                ? _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                    _c(
                      "td",
                      {
                        staticClass:
                          "history-table-left-info d-sm-table-cell d-block",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                          },
                          [_vm._v(_vm._s(_vm.$t("label.trackingNumber")))]
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      { staticClass: "d-sm-table-cell d-block" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "mb-0 text-sm-body-1 text-body-2",
                            attrs: {
                              href:
                                "https://t.17track.net/en#nums=" +
                                _vm.detail.trackingNumber,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.detail.courierName +
                                    " - " +
                                    _vm.detail.trackingNumber
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "backgroundColor--white",
                            attrs: { elevation: "0", tile: "", icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.copyTrackingNumber()
                              },
                            },
                          },
                          [
                            _c("v-icon", {
                              staticClass: "stroke--light_gray",
                              domProps: { textContent: _vm._s("$copyIcon") },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "history-table-left-info d-sm-table-cell d-block",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                      },
                      [_vm._v(_vm._s(_vm.$t("label.orderDate")))]
                    ),
                  ]
                ),
                _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                  _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$shared.getDateFormat(_vm.detail.createdDateTime)
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c(
              "table",
              {
                staticClass:
                  "orderHistory-info-table text-sm-right text-center",
              },
              [
                _c("tr", [
                  _c("td", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 py-2 my-sm-0 text-sm-body-1 text-body-2 font-weight-bold",
                        class: _vm.getStatusColor(_vm.detail.orderStatus),
                      },
                      [_vm._v(" " + _vm._s(_vm.historyOrderStatus) + " ")]
                    ),
                  ]),
                ]),
                !_vm.isPayment &&
                (_vm.detail.orderStatus == "pendingsubmission" ||
                  _vm.detail.orderStatus == "pendingfulfilment" ||
                  _vm.detail.orderStatus == "success")
                  ? _c("tr", [
                      _c("td", [
                        _vm.detail.orderStatus == "pendingsubmission"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-none d-sm-inline-block",
                                    attrs: {
                                      outlined: "",
                                      color: "red",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "cancel"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold",
                                    attrs: {
                                      block: "",
                                      outlined: "",
                                      color: "red",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "cancel"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.detail.orderStatus == "pendingfulfilment" &&
                            _vm.withinRefundPolicyRefundDay(_vm.detail) &&
                            _vm.detail.trackingNumber == null
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-none d-sm-inline-block",
                                    attrs: {
                                      outlined: "",
                                      color: "red",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "void"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold",
                                    attrs: {
                                      block: "",
                                      outlined: "",
                                      color: "red",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "void"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.detail.orderStatus == "success" &&
                            _vm.withinRefundPolicyRefundDay(_vm.detail) &&
                            _vm.detail.transactionType.toLowerCase() !=
                              "preorder"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-none d-sm-inline-block",
                                    attrs: {
                                      outlined: "",
                                      color: "red",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "refund"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("label.refund")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6",
                                    attrs: {
                                      block: "",
                                      outlined: "",
                                      color: "red",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "refund"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("label.refund")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.detail.orderStatus == "success" &&
                            _vm.detail.transactionType.toLowerCase() ==
                              "preorder"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-none d-sm-inline-block",
                                    attrs: {
                                      outlined: "",
                                      color: "red",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "cancelSuccessOrder"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold",
                                    attrs: {
                                      block: "",
                                      outlined: "",
                                      color: "red",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openConfirmationDialog(
                                          _vm.detail,
                                          "cancelSuccessOrder"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("label.cancelOrder")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _vm._l(
        _vm.isCompletedTab
          ? _vm.detail.orderItems.filter(function (item) {
              return item.order_statuses_name.toLowerCase() != "exchanged"
            })
          : _vm.detail.orderItems,
        function (orderItem, index) {
          return _c(
            "v-row",
            {
              key:
                _vm.detail.referenceNumber +
                "_" +
                index +
                "_" +
                orderItem.product_display_sku,
              staticClass: "px-4",
              class: _vm.isPayment ? "" : index >= 1 ? "d-none" : "",
              attrs: { justify: "space-between", "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-sm-left text-center",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c("table", { staticClass: "orderHistory-info-table" }, [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass:
                            "history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0",
                        },
                        [
                          _c("img", {
                            staticClass:
                              "full-width history-product-image-box rounded-lg",
                            attrs: {
                              src:
                                _vm.shared.MEDIA_SERVER_URL +
                                "/products/variants/" +
                                orderItem.product_display_sku +
                                ".png",
                              alt:
                                orderItem.product_name +
                                " " +
                                _vm.getProductVariantDisplay(
                                  orderItem.product_variants
                                ),
                              title:
                                orderItem.product_name +
                                " " +
                                _vm.getProductVariantDisplay(
                                  orderItem.product_variants
                                ),
                            },
                          }),
                        ]
                      ),
                      _c("td", { staticClass: "d-block d-sm-table-cell" }, [
                        _c(
                          "p",
                          { staticClass: "text-body-1 font-weight-bold my-1" },
                          [_vm._v(_vm._s(orderItem.product_name))]
                        ),
                        orderItem.service_pin
                          ? _c(
                              "p",
                              { staticClass: "text-body-2 font-weight-medium" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("label.pin")) +
                                    ": " +
                                    _vm._s(orderItem.service_pin) +
                                    " "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2 backgroundColor--white",
                                    attrs: {
                                      small: "",
                                      elevation: "0",
                                      tile: "",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyServicePin(
                                          orderItem.service_pin
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", {
                                      staticClass: "stroke--light_gray",
                                      attrs: { small: "" },
                                      domProps: {
                                        textContent: _vm._s("$copyIcon"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "text-sm-body-1 text-body-2 my-1" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getProductVariantDisplay(
                                    orderItem.product_variants
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                "" +
                                  _vm.$shared.formatCurrency(
                                    orderItem.selling_price +
                                      orderItem.mark_up_price
                                  )
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-sm-left text-center",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c("span", { staticClass: "text-sm-body-1 text-body-2" }, [
                    _vm._v(_vm._s(_vm.$t("label.quantity") + ": ")),
                  ]),
                  _c("span", { staticClass: "text-h6 font-weight-bold mb-2" }, [
                    _vm._v(_vm._s("x" + orderItem.quantity)),
                  ]),
                ]
              ),
            ],
            1
          )
        }
      ),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "space-between", "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table" }, [
              _c("tr", [
                _c("td", { staticClass: "history-table-left-info" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [_vm._v(_vm._s(_vm.$t("label.totalItems")))]
                  ),
                ]),
                _c("td", { staticClass: "text-right text-sm-left" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.detail.orderItems.filter(function (item) {
                              return (
                                item.order_statuses_name.toLowerCase() !=
                                "exchanged"
                              )
                            }).length +
                              " " +
                              _vm.$t("label.items")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm.detail.discountCode != null
                ? _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.promoCode")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [_vm._v(_vm._s(_vm.detail.discountCode))]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", [
                _c("td", { staticClass: "history-table-left-info" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isProcessingTab
                            ? _vm.$t("label.refundTotal")
                            : _vm.$t("label.orderTotal")
                        )
                      ),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "text-right text-sm-left" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 unbox_red--text font-weight-bold text-sm-body-1 text-body-2",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          "" +
                            _vm.$shared.formatCurrency(
                              _vm.detail.purchasePrice + _vm.detail.markUpPrice
                            )
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table text-right" }, [
              _c("tr", [
                _c("td", [
                  !_vm.isPayment
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-capitalize rounded-lg d-none d-sm-inline-block",
                              attrs: {
                                outlined: "",
                                color: "#64748B",
                                dense: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(_vm.detail)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.viewDetails")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6",
                              attrs: {
                                height: "50",
                                outlined: "",
                                color: "#64748B",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(_vm.detail)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.viewDetails")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  !_vm.isPayment &&
                  _vm.detail.orderStatus !== "pendingsubmission" &&
                  _vm.detail.receiptPath !== undefined
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "d-none d-sm-inline-block backgroundColor--white",
                              attrs: { elevation: "0", tile: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.getReceipt()
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s("$downloadIcon"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 white--text",
                              attrs: {
                                block: "",
                                height: "50",
                                color: "black",
                                elevation: "0",
                                tile: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getReceipt()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.downloadReceipt")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm.detail.orderStatus == "pendingsubmission"
        ? _c(
            "div",
            [
              _c("v-divider", {
                staticClass: "my-4 orderHistory-info-divider",
              }),
              _c(
                "v-row",
                {
                  staticClass: "px-4",
                  attrs: { justify: "space-between", "no-gutters": "" },
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "font-weight-bold mb-0 text-body-2" },
                      [_vm._v(_vm._s(_vm.$t("label.toPayTimerRemark")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.additionalInfoDialogShow,
          title: _vm.$t("label.additionalInfoRequired"),
          closeAction: function () {
            return (_vm.additionalInfoDialogShow =
              !_vm.additionalInfoDialogShow)
          },
          "max-width": 500,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-form",
                      {
                        ref: "additionalInfoForm",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.regenerateReceipt(_vm.additionalInfo)
                          },
                        },
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 unbox_secondary--text",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("message.additionalInfoRequired")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("app-form-field", {
                                  attrs: {
                                    placeholder: _vm.$t("label.email"),
                                    label: _vm.$t("label.email"),
                                    rules: _vm.$validation.emailRules(),
                                  },
                                  model: {
                                    value: _vm.additionalInfo.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.additionalInfo,
                                        "email",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "additionalInfo.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("app-form-field", {
                                  attrs: {
                                    placeholder: _vm.$t("label.phoneNumber"),
                                    label: _vm.$t("label.phoneNumber"),
                                    rules: _vm.$validation.requiredRules(),
                                  },
                                  model: {
                                    value: _vm.additionalInfo.emailPhoneNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.additionalInfo,
                                        "emailPhoneNumber",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "additionalInfo.emailPhoneNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("app-form-field", {
                                  attrs: {
                                    placeholder: _vm.$t("label.billingAddress"),
                                    label: _vm.$t("label.billingAddress"),
                                    rules: _vm.$validation.requiredRules(),
                                  },
                                  model: {
                                    value:
                                      _vm.additionalInfo.emailBillingAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.additionalInfo,
                                        "emailBillingAddress",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "additionalInfo.emailBillingAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("app-form-field", {
                                  attrs: {
                                    placeholder: _vm.$t("label.name"),
                                    label: _vm.$t("label.name"),
                                    rules: _vm.$validation.requiredRules(),
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.additionalInfo.emailCustomerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.additionalInfo,
                                        "emailCustomerName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "additionalInfo.emailCustomerName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                                      attrs: {
                                        block: "",
                                        height: "56px",
                                        type: "submit",
                                        color: "unbox_black",
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.confirm")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                                      attrs: {
                                        text: "",
                                        height: "56px",
                                        block: "",
                                        color: "unbox_black",
                                        elevation: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.additionalInfoDialogShow =
                                            !_vm.additionalInfoDialogShow
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.cancel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }